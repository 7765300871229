import './App.css';
import { useMemo } from 'react';
import * as anchor from '@project-serum/anchor';
import Home from './Home';
import { DEFAULT_TIMEOUT } from './connection';
import { clusterApiUrl } from '@solana/web3.js';
import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';
import {
  getPhantomWallet,
  getSlopeWallet,
  getSolflareWallet,
  getSolletWallet,
  getSolletExtensionWallet,
} from '@solana/wallet-adapter-wallets';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  ConnectionProvider,
  WalletProvider,
} from '@solana/wallet-adapter-react';
import { WalletDialogProvider } from '@solana/wallet-adapter-material-ui';

import { ThemeProvider, createTheme } from '@material-ui/core';

const theme = createTheme({
  palette: {
    type: 'dark',
  },
});

const getCandyMachineId = (): anchor.web3.PublicKey | undefined => {
  try {
    const candyMachineId = new anchor.web3.PublicKey(
      process.env.REACT_APP_CANDY_MACHINE_ID!,
    );

    return candyMachineId;
  } catch (e) {
    console.log('Failed to construct CandyMachineId', e);
    return undefined;
  }
};

const candyMachineId = getCandyMachineId();
const network = process.env.REACT_APP_SOLANA_NETWORK as WalletAdapterNetwork;
const rpcHost = process.env.REACT_APP_SOLANA_RPC_HOST!;
const connection = new anchor.web3.Connection(
  rpcHost ? rpcHost : anchor.web3.clusterApiUrl('devnet'),
);

const App = () => {
  const endpoint = useMemo(() => clusterApiUrl(network), []);

  const wallets = useMemo(
    () => [
      getPhantomWallet(),
      getSolflareWallet(),
      getSlopeWallet(),
      getSolletWallet({ network }),
      getSolletExtensionWallet({ network }),
    ],
    [],
  );
  function toggleMenu() {
    const menu = document.getElementById("mobileNavContainer");
    menu!.classList.toggle("open-menu");
    console.log("pressed");
  }
  return (
    <div className='main'>
      <div id="mobileNavContainer" className="mobile-nav">
        <div className="mobile-nav-close-button">
          <img src="/icons/close.svg" alt="" onClick={toggleMenu} loading="lazy"/>
        </div>
        <ul>
          <li>
            <a className="navlogocont" href="/"><img className="mobile-nav-logo" src="logo.gif" loading="lazy"></img></a>
          </li>
          <li>
            <a href="/#about" onClick={toggleMenu}>ABOUT</a>
          </li>
          <li>
            <a href="/#roadmap" onClick={toggleMenu}>ROADMAP</a>
          </li>
          <li>
            <a href="/#team" onClick={toggleMenu}>TEAM</a>
          </li>
          <li>
            <a href="/#faq" onClick={toggleMenu}>FAQ</a>
          </li>
          <li>
            <div className="social-icons">
              <a href="https://www.instagram.com/host_the_ghost_/" target="_blank" rel="noreferrer">
                <img className="nav-social" src="/icons/instagram.svg" alt="" loading="lazy"/>
              </a>
              <a href="https://twitter.com/HostTheGhost_" target="_blank" rel="noreferrer">
                <img className='nav-social' src="/icons/twitter.svg" alt="" loading="lazy"/>
              </a>
              <a href="https://discord.gg/xTJcbrkAWf" target="_blank" rel="noreferrer">
                <img className='nav-social' src="/icons/discord.svg" alt="" loading="lazy"/>
              </a>
            </div>
          </li>
          <li>
          <div className='mebutton'>
              <a href="https://magiceden.io" target="_blank" rel='noreffer'>VIEW ON MAGIC EDEN</a>
            </div>
          </li>
        </ul>
      </div>
      <div className="mobile-menu-button" onClick={toggleMenu}>
        <img src="/icons/menu.svg" alt="" />
      </div>

      <section className='hero'>
        <nav className="navbar">
          <div className="nav-container">
            <a className="navlogocont" href="/"><img className="nav-logo" src="logo.gif" loading="eager"></img></a>
            <a className='hide-800' href="/#about">ABOUT</a>
            <a className='hide-800' href="/#roadmap">ROADMAP</a>
            <a className='hide-800' href="/#team">TEAM</a>
            <a className='hide-800' href="/#faq">FAQ</a>
            <div className='mebutton hide-800'>
              <a href="https://magiceden.io" target="_blank" rel='noreffer'>VIEW ON MAGIC EDEN</a>
            </div>
            <div className="social-icons hide-800">
            <a href="https://www.instagram.com/host_the_ghost_/" target="_blank" rel="noreferrer">
                <img className="nav-social" src="/icons/instagram.svg" alt="" loading="lazy"/>
              </a>
              <a href="https://twitter.com/HostTheGhost_" target="_blank" rel="noreferrer">
                <img className='nav-social' src="/icons/twitter.svg" alt="" loading="lazy"/>
              </a>
              <a href="https://discord.gg/xTJcbrkAWf" target="_blank" rel="noreferrer">
                <img className='nav-social' src="/icons/discord.svg" alt="" loading="lazy"/>
              </a>
            </div>

          </div>
        </nav>
        <div className="flexfirst">
          <p>9797 souls in pain in 9797 NFT. <br />
            Their bodies have rotted but<br /> their  items resist. <br />
            <span>Host a ghost now!</span></p>
          <div className='mobi'>
            <img src="/assets/heading.png" className='heading' alt="" loading="lazy"/>
            <div className='mintsec'>
              <ThemeProvider theme={theme}>
                <ConnectionProvider endpoint={endpoint}>
                  <WalletProvider wallets={wallets} autoConnect>
                    <WalletDialogProvider>
                      <Home
                        candyMachineId={candyMachineId}
                        connection={connection}
                        txTimeout={DEFAULT_TIMEOUT}
                        rpcHost={rpcHost}
                        network={network}
                      />
                    </WalletDialogProvider>
                  </WalletProvider>
                </ConnectionProvider>
              </ThemeProvider>
            </div>
          </div>
        </div>
        <img src="/assets/ghosts.png" className='ghosts' alt="" loading="lazy"/>

      </section>
      <section className='story' id="about">
        <h1>OUR STORY</h1>
        <p>
          Welcome to HOST THE GHOST, a collection of 9797 NFTs that haunt the Solana blockchain, where your NFT doubles as an entry token to our Ghostaverse. <br />
          We are committed to creating a unique project, our artist has drawn in high resolution, one by one, the 200 assets that make up the collection, the Ghosts of HOST THE GHOST are unique, all full of colors, chubby, foolish, and terribly dead, but not only! <br />
          Our main goal has always been to realize our vision of the metaverse: The ghostaverse. <br />
          This is why we are creating Thamaghosting and Ghostown, a play to earn based on community, of immediate use and easy to understand for everyone, we want to bring NFTs, and the potential they have, literally into everyone's pockets.
        </p>
        <img src="/assets/ghoststory.png" className='ghoststory' alt="" />
      </section>
      <section className='roadmap' id="roadmap">
        <img src="/assets/roadmap.png" alt="" />
      </section>
      <section className='team' id="team">
        <h1>MEET THE TEAM</h1>
        <div className="teamflex">
          <img src="/assets/team1.png" alt="" loading="lazy"/>
          <img src="/assets/team2.png" alt="" loading="lazy"/>
          <img src="/assets/team3.png" alt="" loading="lazy"/>
        </div>
        <div className="teamflex">
          <img src="/assets/team4.png" alt="" loading="lazy"/>
          <img src="/assets/team5.png" alt="" loading="lazy"/>
        </div>
      </section>
      <section className='faq' id="faq">
        <h1>FAQ</h1>
        <div className="faqwrap">
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>WHO WE ARE?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                A team of enthusiasts from the NFT world, technologies, videogames, and art, among us there are developers, artists, social media managers, writers, and other people who have the desire to create their project by transmitting the vibe that brings us together.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>What is Host the Ghost's vision?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Is to create a healthy and long-term community, which is passionate about the project and its evolution with us. It will be thanks to you if we manage to launch the metaverse and the craziest gamification project ever. Are you ready to ghost everyone?
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>Where can I buy a ghost?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                You can buy ghosts on our site on mint day (October 31, 2022) until sold out. Then you can buy them on Opensea.          </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>What does mint mean?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Mint is the process of creating an NFT for the first time, when the project is launched, people will be able to mint their NFT via our website, paying for it with Solana from their wallet. By doing so you will be the first owners.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>What is the difference between pre-sale and public sale?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                The pre-sale is reserved for users who are in one of the whitelists, these users will have advantages, such as a lower purchase price or free drops. The public sale will happen right after the pre-sale, and everyone will be able to mint their NFT until they run out.          </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>How can I enter the whitelist?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                To enter the whitelist you have to go to our Discord. The requirements are very simple, you must invite at least 5 friends to our discord server (the invitation is not enough, they will have to enter the server), follow us on Twitter and Instagram, and register your wallet address in the dedicated section of discord. You may also be able to whitelist by proving yourself an active discord member, participating in discussions, and leveling up. 500 people will be able to enter whitelist 1, and 2000 people will be able to enter whitelist 2, after they are filled they will be closed and can only be purchased in public sale.          </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>What are the benefits of whitelists?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                -WL1: 500 spots, the price for each ghost is 1.5 SOL, if you mint at least 2 ghosts you will be dropped another one for free. <br /> <br />
                -WL2: 2000 spots, the price for each ghost is 2 SOL, if you mint at least 2 Ghosts you will participate in the post-mint lottery to receive free personalized merchandise with your Ghost.          </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>Is there a limit of Ghosts I can buy?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Yes, a maximum of 3 NFTs can be bought for each wallet.          </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>What will the price of a ghost be?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                For whitelist1 1.5 SOL, for whitelist2 2 SOL, for public sale 2.5 SOL.          </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>How many ghosts will there be in the collection?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                The collection includes 9797 ghosts, all unique and different from each other. Among them, 6 will be special ghosts, designed to be legendary.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>What does it mean that all ghosts will be unique?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                It means we have designed over 200 assets that will mix to make each ghost unique and cute.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>What will happen after mint?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Check out our roadmap and our social networks, we have great plans for the future!          </Typography>
            </AccordionDetails>
          </Accordion>
        </div>
        <img src="/assets/hostbottom.png" alt="" loading="lazy"/>

      </section>

      <footer>
        <img src="logo.gif" className="footlogo" alt="" loading="lazy"/>
        <div className="foot-text">
          <div className="social-icons hide-800">
          <a href="https://www.instagram.com/host_the_ghost_/" target="_blank" rel="noreferrer">
                <img className="nav-social" src="/icons/instagram.svg" alt="" loading="lazy"/>
              </a>
              <a href="https://twitter.com/HostTheGhost_" target="_blank" rel="noreferrer">
                <img className='nav-social' src="/icons/twitter.svg" alt="" loading="lazy"/>
              </a>
              <a href="https://discord.gg/xTJcbrkAWf" target="_blank" rel="noreferrer">
                <img className='nav-social' src="/icons/discord.svg" alt="" loading="lazy"/>
              </a>
            <div className='mebutton'>
              <a href="https://opensea.io/collection/" target="_blank" rel="noreferrer">
                VIEW ON MAGIC EDEN
              </a>
            </div>
          </div>
          <div className="social-icons hideplus">
            <div className="flexmobi">
            <a href="https://www.instagram.com/host_the_ghost_/" target="_blank" rel="noreferrer">
                <img className="nav-social" src="/icons/instagram.svg" alt="" loading="lazy"/>
              </a>
              <a href="https://twitter.com/HostTheGhost_" target="_blank" rel="noreferrer">
                <img className='nav-social' src="/icons/twitter.svg" alt="" loading="lazy"/>
              </a>
              <a href="https://discord.gg/xTJcbrkAWf" target="_blank" rel="noreferrer">
                <img className='nav-social' src="/icons/discord.svg" alt="" loading="lazy"/>
              </a>
            </div>
            
            <div className='mebutton'>
              <a href="https://opensea.io/collection/" target="_blank" rel="noreferrer">
                VIEW ON MAGIC EDEN
              </a>
            </div>
          </div>
          <div className="foot-links">
            <a href="/#about">ABOUT</a>
            <a href="/#roadmap">ROADMAP</a>
            <a href="/#team">TEAM</a>
            <a href="/#faq">FAQ</a>
            <a href="/terms.pdf" target="_blank">TERMS</a>
            <a href="/privacy.pdf" target="_blank">PRIVACY</a>
          </div>
          <h2>JOIN US NOW AND HOST A GHOST!</h2>
          <h3>COPYRIGHT © 2022 HOST THE GHOST. ALL RIGHTS RESERVED.</h3>
        </div>
      </footer>
    </div>
  );
};

export default App;
